/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(window).on("load resize", function equalHeights() {
          $('.row').each(function(){
            var highestBox = 0;
            $(this).find('.mh').css("min-height", 0);
            if($(window).width() > 768) {
              $(this).find('.mh').each(function() {
                if ($(this).outerHeight() > highestBox) {
                  highestBox = $(this).outerHeight();
                }
              });
              $(this).find('.mh').css("min-height", highestBox);
            }
          });
        }).resize();

        $(window).on("load resize", function equalHeights() {
          if($(window).width() < 1025) {
            $('#logo').attr('src', $('#logo').attr('src').replace("wit", "kleur"));
            $('header').addClass('white');
          }
        });

        function createMoreMenu(menu, width) {
          if($(window).width() > width) { //get body width without(!) scrollbar
            var w = 0;
            var mw = $(menu).parent().width() - 60; //100 = width of 'more' item
            var menuhtml = '';
            $(menu).children().each(function() {
              w += $(this).outerWidth(true);
              if (mw < w && !$(this).find('a').hasClass('more')) {
                menuhtml += $('<div>').append($(this).clone()).html();
                $(this).remove();
              }
            });
            if(menuhtml) {
              $(menu + " .dropdown.more>ul").html('');
              $(menu + " .dropdown.more>ul").append(menuhtml);
              $(menu + " .dropdown.more").removeClass('hidden-lg-up').removeClass('hidden-lg-down');
            }
          }
        }

        $('header ul.more').each(function() {
          var menu = '#' + $(this).attr('id');
          var width = $(this).data('breakpoint') || 1025;
          var originalMenu = $(menu).clone();
          createMoreMenu(menu, width); $(menu).removeClass('vh'); $(originalMenu).removeClass('vh');
          $(window).on("resize", function() { $(menu).replaceWith(originalMenu.clone()); createMoreMenu(menu, width); });
        });

        $('.clients__slideshow').flickity({
          contain: true,
          pageDots: false
        });
        $('.events__slideshow').flickity({
          contain: true
        });
        $('.services__slideshow').flickity({
          contain: true,
          wrapAround: false,
          cellAlign: 'left',
          watchCSS: true,
          prevNextButtons: false
        });
        //new WOW().init();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(".nav-toggle").click(function(event) {
            var scrollHeight = $(window).scrollTop();
            $(".nav-wrapper, .nav-toggle, body").toggleClass("open");
            $('.search').removeClass('open');
            event.preventDefault();
            if($('.nav-toggle').hasClass('open')) {
              $('.nav-toggle').attr('data-height', scrollHeight);
            } else {
              $(window).scrollTop($('.nav-toggle').attr('data-height'));
            }
        });

        $('a[href="/contact"], a[href="#contact"], ul.nav a.btn.btn-trans').click(function() {
          console.log('contact open!');
          $(".nav-wrapper, .nav-toggle, body").removeClass("open");
          $('.search').removeClass('open');
          $('#contact, body').toggleClass('open');
        });
        $('.contact .close, .contact .contact__background').click(function() {
          $('.search').removeClass('open');
          $('#contact, body').removeClass('open');
        });

        $(window).scroll(function () {
          $('.search').removeClass('open');
          var currentScrollTop = $(window).scrollTop();
          if($('.banner.front-page').length) {
            $('.banner.front-page').css('background-position-y', currentScrollTop/3);
            //$('.img-wrapper.img1').css('background-position-y', ($('.img-wrapper.img1').offset().top - currentScrollTop ) /3);
          }
          if($('header').length && $('header').hasClass('frontpage')) {
            if(currentScrollTop > 0){
              $('#logo').attr('src', $('#logo').attr('src').replace("wit", "kleur"));
              $('header').addClass('white');
            } else if($(window).width() > 1025) {
              $('#logo').attr('src', $('#logo').attr('src').replace("kleur", "wit"));
              $('header').removeClass('white');
            }
          }
          if($('.single-vacancie .apply').length) {
            if( (currentScrollTop + $(window).height()) < ($('footer').offset().top )) {
              $('.single-vacancie .apply').addClass('fixed');
            } else {
              $('.single-vacancie .apply').removeClass('fixed');
            }
          }
        });

        $('a[href="#search"]').click(function(e) {
          e.preventDefault();
          //$('a[href="#search"] i').toggleClass('fa-close');
          $(".nav-wrapper, .nav-toggle, body").removeClass("open");
          $('.search').toggleClass('open');
          $('input[name="s"]').focus();
        });
        $('main, footer').click(function() {
          $('.search').removeClass('open');
        });
        $('form.search-form').submit(function(e) {
          if(!$('input[name="s"]').val()) {
            e.preventDefault();
          }
        });

        $('.banner .after').click(function(e) {
          e.preventDefault();
          $('html,body').animate({
            scrollTop: $('.services').offset().top - 50
          }, 500);
        });
        //$(".archive .wblock").stick_in_parent({'offset_top':50});
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
